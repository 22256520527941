import { Fa500Px, FaTools, FaUser } from "react-icons/fa";
import { MdOutlineHandshake } from "react-icons/md";
import { IoMdPeople } from "react-icons/io";
import { AiOutlineSafetyCertificate } from "react-icons/ai";

const Data = [
  {
    services: [
      {
        title: "Safety Above All",
        description:
          "We prioritize safety as the cornerstone of our service. Every action we take, from chauffeur recruitment to technology implementation, is focused on ensuring the highest standards of safety for our passengers and the community.",
        icons: AiOutlineSafetyCertificate,
      },
      {
        title: "Zero Tolerance Policy  ",
        description:
          "We maintain a strict zero-tolerance policy towards drunk driving within our network. Our chauffeurs undergo meticulous screening processes and adhere to stringent guidelines prohibiting any form of impaired driving",
        icons: Fa500Px,
      },
      {
        title: "Our Commitment",
        description:
          "Your safety is our priority. Our chauffeurs are meticulously vetted, undergoing rigorous background checks and continuous training to guarantee the highest standards of safety and professionalism.",
        icons: MdOutlineHandshake,
      },
      {
        title: "Unparalleled Convenience",
        description:
          "Say goodbye to the hassles of driving, parking, or navigating traffic. With our seamless booking process and prompt service, we make it effortless for you to request a ride when you need it. Sit back, relax, and let our expert chauffeurs take care the rest.",
        icons: FaTools,
      },
      {
        title: "Customer Satisfaction ",
        description:
          "Your satisfaction matters to us. We are committed to exceeding your expectations at every step, ensuring that each ride with DrinkDrive leaves you delighted and eager to ride with us again.",
        icons: FaUser,
      },
      {
        title: "Community Approach",
        description:
          "We take pride in being an integral part of the communities we serve. By fostering local partnerships, supporting charitable initiatives, and promoting responsible transportation practices, we strive to make a positive impact beyond just rides.",
        icons: IoMdPeople,
      },
    ],
  },
];

export default Data;
