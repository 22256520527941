import React from "react";

import Data from "../../Data";

const Services = () => {
  return (
    <>
      <div
        id="service"
        className="bg-slate-800/10 h-full max-md:w-screen pt-16 px-10 max-md:px-8"
      >
        <div className="flex flex-col gap-6 max-md:gap-8">
          <h1 className="text-main text-md uppercase font-bold">Services</h1>
          <div className="flex flex-col items-center gap-8 text-center">
            <h1 className="text-black text-[62px] max-md:text-4xl font-bold max-md:w-[350px] w-[630px]">
              High <span className="text-main">Quality</span> &
              <span className="text-main">&nbsp;Fastest</span> Chauffuer Service
            </h1>
            <p className="text-secondary max-md:w-[350px] w-[630px]  font-semibold text-xl max-md:text-[18px]">
              Earning your trust since 2016, DrinkDrive is always committed to
              getting you to your destination safely.
            </p>
          </div>
        </div>

        <div>
          {Data.map((e, i) => {
            return (
              <div className="grid grid-cols-[350px,350px,350px] max-md:grid-cols-[350px] py-6 gap-8 justify-center items-center max-md:items-center">
                <>
                  {e.services.map((e, i) => {
                    return (
                      <div
                        className="bg-black shadow-xl w-[350px] h-[370px] border flex flex-col gap-6 p-4 text-white"
                        key={i}
                      >
                        <div className="flex items-center gap-6 leading-[20px]">
                          <span className="text-main text-xl">
                            <e.icons />
                          </span>
                          <h1 className="font-bold text-lg ">{e.title}</h1>
                        </div>
                        <h2 className="description">{e.description}</h2>
                      </div>
                    );
                  })}
                </>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Services;
