import React from "react";
import priceimg from "../../assets/price-img.png";

const Pricing = () => {
  return (
    <>
      <div
        id="price"
        className="bg-slate-800/10 h-full max-md:w-screen py-16 px-10 max-md:px-8"
      >
        <div className="flex flex-col gap-6">
          <h1 className="text-main text-md uppercase font-bold">Pricing</h1>
          <p className="text-lg font-medium">
            At DrinkDrive, we offer transparent and competitive pricing designed
            to provide you with exceptional value for our on-demand chauffeur
            services. Our pricing structure is straightforward and ensures you
            know what to expect before you book a ride.
          </p>
          <div className="my-6 flex flex-col items-center">
            <img src={priceimg} alt="drinkanddrive" className="w-[300px]" />
          </div>
        </div>
        <div>
          <div className="grid grid-cols-[350px,350px,350px] max-md:grid-cols-[350px] py-10 gap-8 justify-center items-center ">
            <div className="bg-black shadow-xl w-[350px] h-[250px] border flex flex-col gap-6 p-4 text-white">
              <h1>Base Fare</h1>
              <p>
                Begins with a <span className="text-main">LKR 2,000</span>
              </p>
              <p>covers the initial 10km</p>
              <p>15 minutes waiting</p>
            </div>
            <div className="bg-black shadow-xl w-[350px] h-[250px] border flex flex-col gap-6 p-4 text-white">
              <h1>Distance and Waiting</h1>
              <p>
                <span className="text-main">LKR 100 </span>per kilometer
              </p>
              <p>
                <span className="text-main">LKR 30</span> per minute
              </p>
            </div>
            <div className="bg-black shadow-xl w-[350px] h-[250px] border flex flex-col gap-6 p-4 text-white">
              <h1>Surge Pricing</h1>
              <p>
                <span className="text-main">LKR 50</span> per minute
              </p>
              <p className="text-tertiary font-semibold">
                *You will always be informed of any changes in pricing before
                confirming your booking
              </p>
            </div>
            <div className="bg-black shadow-xl w-[350px] h-[250px] border flex flex-col gap-6 p-4 text-white">
              <h1>Out of Operational Area</h1>
              <p>
                <span className="text-main">LKR 500</span> will be applied to
                your base fare
              </p>
              <p className="text-tertiary font-semibold">
                *If your pickup or drop-off location falls beyond our
                operational area range
              </p>
            </div>
            <div className="bg-black shadow-xl w-[350px] h-[250px] border flex flex-col gap-6 p-4 text-white">
              <h1>Cancellation Fee</h1>
              <p>
                A cancellation fee of{" "}
                <span className="text-main">LKR 1000 </span>be charged.
              </p>
              <p className="text-tertiary font-semibold">
                *In case of a canceled ride after the chauffeur arrives at your
                pick up point
              </p>
            </div>
            <div className="bg-black shadow-xl w-[350px] h-[250px] border flex flex-col gap-6 p-4 text-white">
              <h1>Tolls and Fees</h1>
              <p>
                An additional charge of{" "}
                <span className="text-main">LKR 500 </span> will be added to the
                base fare
              </p>
              <p className="text-tertiary font-semibold">
                *Any applicable tolls, airport fees, or government taxes will be
                added to your fare.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
