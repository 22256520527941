import React, { useEffect } from "react";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="p-8">
        <p id="terms">
          <span className="font-bold text-2xl">Bookings</span>
          <br />
          <br />
          Bookings are accepted via email, web application, telephone or through
          the mobile application. Services are provided on an ‘as available
          basis’. You may make amendments to the booking, subject to relevant
          prior notification to the management. Drivers do not have the
          authority to alter the booked times, pick up or drop off destination
          or routes without the prior consent of the management. You would be
          charged an additional fee for deviations from the original booking
          instructions. Such additional payments should be made to the chauffeur
          at the drop off location or at the end of the provision of services.
          <br />
          <br />
          <span className="font-bold text-2xl">Members</span>
          <br />
          <br /> All the bookings are interrogated with vehicle numbers which
          have been registered at the initial registration. Service will be
          offered in the presence of the registered member only. Member can
          alter the vehicle numbers with a prior notice to hello@jolliber.com.
          Only one amendment is allowed per year. Membership entitlements and
          discounted rates granted for the members of partner outlets cannot be
          applied simultaneously. <br />
          <br />
          <span className="font-bold text-2xl">Rates</span>
          <br />
          <br />
          Package rates do not include tolls, parking fees, fuel, meals, and
          accommodation, waiting time, entrance fees or other incidental costs.
          <br />
          <br />
          <span className="font-bold text-2xl">Cancellation</span>
          <br />
          <br /> A cancellation fee will be applied upon the cancellation of a
          booking on management discretion. However, outstanding payments made
          will be set off against any future bookings subject to any deductions
          and will not be refundable.
          <br />
          <br /> <span className="font-bold text-2xl">Drivers</span>
          <br />
          <br /> All chauffeur, car detailers &amp; riders possess a valid
          driving license, well trained, polite, courteous and screened to carry
          out the services. The management would inquire, any complaints related
          to the chauffeurs, may be notified via email or a phone call with
          details. any future bookings subject to any deductions and will not be
          refundable.
          <br />
          <br />
          <span className="font-bold text-2xl"> Limitation of Liability</span>
          <br />
          <br /> You are entirely responsible for the whereabouts and
          safekeeping of your personal belongings. We will accept no liability
          for loss or damage at any time under any circumstance. Our terms and
          conditions apply exclusively for the supply of the services mentioned
          herein and are not binding for additional services. In any event we
          will not be liable, whether in tort, in contract or otherwise for any
          accident, delay, injury, irregularity, loss or damage to persons or
          property for any kind whatsoever and howsoever arising (including but
          not limited to any negligence or breach of contract by us or the
          chauffeurs) caused or incurred during or in relation to the provision
          of service mentioned herein. We accept no liability for any business
          loss, which includes loss of contracts, loss of profit, loss of
          revenue or loss of anticipated savings. We reserve the right to
          subcontract the provision of the services to our chosen third-party
          subcontractors and our liability is limited to ensuring that we use
          due care and skill in selection of the subcontractor. In any instance
          our liability will be only up to the value paid for the services.
          <br />
          <br /> <span className="font-bold text-2xl">Warranty</span>
          <br />
          <br /> The client represents and warrants that he/she/it is the owner
          of the vehicle used for the provision of services or that he/she/it
          has obtained the consent from the owner of the vehicle and has the
          authority to use the same and further represents and warrants that
          his/her/its vehicle is insured and possess a valid revenue license.
          inquire, any complaints related to the chauffeurs, may be notified via
          email or a phone call with details. any future bookings subject to any
          deductions and will not be refundable.
          <br />
          <br />
          <span className="font-bold text-2xl">Indemnity</span>
          <br />
          <br /> The client shall indemnify, defend and hold harmless OMBC
          Solutions (Pvt.) Limited, its directors, officers, shareholders,
          employees, representatives, agents and chauffeurs against any and all
          loss, injury, damage, cost or expense, arising out of or occurring in
          connection with non-compliance of the terms and conditions mentioned
          herein, breach of warranties or any act, omission, negligence and
          willful misconduct by the client or its authorized agent or violation
          of any applicable laws regulations. deductions and will not be
          refundable. <br />
          <br />
          <span className="font-bold text-2xl">
            The client shall remain trustworthy on transportation of lawful
            properties. Unforeseen Circumstances
          </span>
          <br />
          <br /> We will make every effort to arrive at your location on time.
          However, occasionally; allowance must be made for unforeseen
          circumstances such as traffic, road and weather conditions, illness,
          vehicle breakdown etc. which will be duly communicated beforehand.
          deductions and will not be refundable. <br />
          <br />
          <span className="font-bold text-2xl">Unacceptable Conduct</span>
          <br />
          <br /> We reserve the right to terminate the provision of services at
          any time in the event the chauffeur is subject to threatening,
          abusive, unlawful or inappropriate behavior or any other circumstances
          when a client’s conduct may prejudice the safety of themselves and the
          chauffeur and also in the event whereby the chauffeur has reasonable
          belief that the client is about to engage/ engaged in any
          criminal/unlawful activity.
          <br />
          <br />
          <span className="font-bold text-2xl">Personal Information</span>
          <br />
          <br /> The personal information disclosed to us will be used for the
          purpose of providing the services only. Your personal information will
          not be given to any third party except where the purpose relates to
          fulfilling the obligations related to the services. We may retain your
          information for use in future bookings. If, at any time you discover
          that the information held is incorrect, you may contact us to have the
          information corrected. We may be obliged to surrender your personal
          information to regulatory authorities and law enforcement officials in
          accordance with the applicable laws. Personal information may be
          disclosed to identify, contact or bring legal action against anyone,
          in the event of theft, illegal activity, injury or damage caused to
          any party including the chauffeur <br />
          <br />
          <span className="font-bold text-2xl">Force Majeure</span>
          <br />
          <br /> We shall not be liable for any delay, failure of performance of
          our obligations under these terms and conditions for any cause beyond
          our reasonable control including act of god, governmental act, war,
          fire, flood, explosion or civil commotion, failure in information
          technology or telecommunication services or any industrial action.
          <br />
          <br />
          <span className="font-bold text-2xl">Invalidity</span>
          <br />
          <br /> In the event any provision of these terms and conditions are
          found by any court or competent authority to be invalid, unlawful or
          unenforceable in any court having jurisdiction, that provision shall
          be deemed not to be a part of this terms and conditions, it shall not
          affect the enforceability of the remainder of the terms and conditions
          nor shall it affect the validity, lawfulness or enforceability of that
          provision in any other applicable jurisdiction. <br />
          <br />
          <span className="font-bold text-2xl">
            Governing Law and Jurisdiction
          </span>
          <br />
          <br /> The provisions of this Agreement shall be governed by and
          construed in accordance with the Laws of Sri Lanka and the parties
          agree that the Courts of Sri Lanka shall have power to determine all
          disputes or matters pertaining or arising under or in relation to this
          agreement.
          <br />
          <br />
          <span className="font-bold text-2xl">Acceptance</span>
          <br />
          <br /> The use of our services is considered acceptance of these terms
          and conditions, deviations from these conditions, will be considered
          accepted only upon our written consent.
        </p>
      </div>
    </>
  );
};

export default Terms;
