import React from "react";
import { Link } from "react-router-dom";
import contactimg from "../../assets/contact-img.png";
import addressimg from "../../assets/address-img.png";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";

import emailjs from "emailjs-com";

const Contact = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_pauwdlm",
        "template_ujkpxvj",
        e.target,
        "dV7v3Mn5hpk62ps0b"
      )
      .then((res) => {
        console.log(res);
        e.target.reset();
      })
      .catch((err) => {
        console.log("err");
      });
  }
  return (
    <div
      id="contact"
      className="bg-black flex flex-col gap-7 py-16 px-10 max-md:w-screen"
    >
      {/* top */}
      <div className="flex max-md:flex-col items-center justify-center gap-[100px] max-md:gap-[50px]">
        <div>
          <img src={contactimg} alt="drinkandrive" />
        </div>
        <div className="flex flex-col items-center gap-5">
          <h1 className="uppercase text-main text-md font-bold">book now</h1>
          <p className="text-tertiary text-center font-semibold">
            Fill this form to book your chauffeur
          </p>
          <form onSubmit={sendEmail}>
            <div className="flex flex-col gap-10 w-[465px] max-md:w-[260px] max-md:items-center">
              <div>
                <input
                  type="text"
                  name="name"
                  className="w-full max-md:w-[260px] p-3 outline-0 border-0"
                  placeholder="Name"
                  required
                />
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  className="w-full max-md:w-[260px] p-3 outline-0 border-0"
                  placeholder="Email Address"
                  required
                />
              </div>
              <input
                type="text"
                name="mobile"
                className="w-full max-md:w-[260px] p-3 outline-0 border-0"
                placeholder="Contact Number"
                required
              />
              <div>
                <input
                  type="text"
                  name="p_location"
                  className="w-full max-md:w-[260px] p-3 outline-0 border-0"
                  placeholder="Pickup Location"
                  required
                />
              </div>
              <div>
                <input
                  type="text"
                  name="d_location"
                  className="w-full max-md:w-[260px] p-3 outline-0 border-0"
                  placeholder="Dropdown Location"
                  required
                />
              </div>
              <div>
                <input
                  type="text"
                  name="date"
                  className="w-full max-md:w-[260px] p-3 outline-0 border-0"
                  placeholder="Date"
                  required
                />
              </div>
              <div>
                <input
                  type="text"
                  name="time"
                  className="w-full max-md:w-[260px] p-3 outline-0 border-0"
                  placeholder="Enter time"
                  required
                />
              </div>
              <div className="flex flex-col w-full max-md:w-[260px] max-md:flex-col max-md:text-center gap-5 max-md:gap-10 items-center ">
                <button
                  type="submit"
                  className="bg-main w-full max-md:w-full text-white font-bold p-3"
                >
                  Submit
                </button>
                <p className="max-md:w-full text-tertiary text-md font-semibold">
                  *Please enter correct information
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* bottom */}
      <div className="flex max-md:flex-col items-center justify-center gap-[100px] max-md:gap-0">
        <div className="flex flex-col gap-6">
          <h1 className="text-white font-bold text-xl">Customer Support</h1>
          <p className="w-[500px] max-md:w-[300px] text-tertiary">
            For any inquiries regarding bookings, rides, or general assistance,
            our customer support representatives are available 24/7 to address
            your needs.
          </p>
          <div className="text-tertiary flex gap-4">
            <div className="border-l-[4px] border-white h-[350px]" />
            <div className="flex flex-col justify-evenly h-[350px]">
              <div className="flex items-center gap-4">
                <FaPhoneAlt />
                <a href="tel:+94114555040">+94 11 4 555 040</a>
              </div>
              <div className="flex items-center gap-4">
                <MdEmail />
                <a href="mailto:Alladinpronxt@gmail.com">
                  bookings@drinkdrive.lk
                </a>
              </div>
              <div className="flex  gap-4">
                <IoLocationSharp />
                <p className="w-[250px]">
                  OMBC Solutions Pvt Ltd No 4, Pepiliyana Road, DehiwalaColombo,
                  Western Province , 10290Sri Lanka
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          src={addressimg}
          alt="drinkdrive"
          className="max-md:w-[200px] max-md:mt-0"
        />
      </div>
      <div className="border-b w-full max-md:w-[260px] px-[12rem] max-md:px-0 py-4 border-tertiary text-white flex  max-md:flex-col justify-between max-md:gap-5">
        <Link to="/terms"> Terms and Conditions</Link>
        <div className="flex gap-5 justify-between items-center">
          <p>Follow us on</p>
          <a
            href="https://web.facebook.com/DrinkDriveLK"
            className=" hover:text-main delay-100 cursor-pointer"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://www.instagram.com/drinkdrivelk/"
            className=" hover:text-main delay-100 cursor-pointer"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.linkedin.com/company/drinkdrive/"
            className=" hover:text-main delay-100 cursor-pointer"
          >
            <FaLinkedinIn />
          </a>
        </div>
      </div>
      <div className="flex justify-between w-full">
        <p className="text-tertiary px-[12rem] max-md:px-0 max-md:text-[14px]">
          &copy;DrinkDrive 2023.All Rights Reserved
          <p className="text-tertiary text-center pt-8">
            Designed and developed by Webizera
          </p>
        </p>
      </div>
    </div>
  );
};
export default Contact;
