import React from "react";
import serviceimg from "../../assets/service-img.png";
import { FaPhoneAlt } from "react-icons/fa";

const Home = () => {
  return (
    <>
      <div
        id="home"
        className="bg-slate-800/10 max-md:w-screen h-screen max-md:h-screen"
      >
        <div className="flex flex-col items-center justify-center text-center  max-md:gap-12 h-screen max-md:h-screen max-md:px-8 ">
          <div className="flex flex-col items-center gap-16 max-md:gap-10 max-md:pt-10">
            <h6 className="uppercase font-cursive text-[48px] max-md:text-[42px] text-center ">
              “IF YOU <span className="text-main">drink,</span> DON`T
              <span className="text-main">&nbsp;drive"</span>
            </h6>
            <img src={serviceimg} alt="drinkdrive" />
            <div className="bg-main text-white flex items-center p-4 rounded-full gap-2 font-bold md:hidden cursor-pointer">
              <FaPhoneAlt />
              <a href="tel:+94114555040">Call your chauffeur</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
