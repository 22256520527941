import React from "react";
import aboutimg from "../../assets/abt-img.png";
const About = () => {
  return (
    <div
      id="about"
      className="bg-black text-white h-full max-md:w-full flex flex-col gap-14 py-16 justify-center items-center"
    >
      <div className="flex max-md:flex-col items-center gap-[150px] max-md:gap-[80px] justify-center ">
        <div className="flex flex-col gap-5 text-justify ">
          <h6 className="text-main text-lg max-md:text-sm max-md:px-8 uppercase font-bold">
            About Us
          </h6>
          <h1 className="text-[38px] max-md:text-[24px] max-md:px-8 font-medium">
            What is DrinkDrive?
          </h1>
          <p className="capitalize w-[500px] max-md:w-full max-md:p-8">
            Welcome to DrinkDrive, your trusted partner in revolutionizing how
            you move! We are a leading on-demand chauffeur service committed to
            redefining transportation with a focus on safety, reliability, and
            convenience.
            <br></br>
            <br></br>
            At DrinkDrive , we understand that getting from point A to point B
            should be more than just a commute; it should be an experience
            that's secure, stress-free, and personalized to your needs. That's
            why we've assembled a team of professional chauffeurs dedicated to
            providing top-notch service tailored to your schedule and
            preferences.
          </p>
        </div>
        <img
          src={aboutimg}
          alt="drink and drive"
          width="350"
          height="300"
          className="max-md:w-[300px]"
        />
      </div>
      <div className="flex max-md:flex-col gap-[70px] max-md:items-center">
        <div className="max-md:flex max-md:flex-col text-center max-md:items-center">
          <h1 className="text-main font-bold text-[64px]">100%</h1>
          <p>customer satisfaction</p>
        </div>
        <div className="max-md:flex max-md:flex-col text-center max-md:items-center">
          <h1 className="text-main font-bold text-[64px]">25000+</h1>
          <p>Customers</p>
        </div>
      </div>
      <div className="flex max-md:flex-col gap-[100px] max-md:p-8">
        <div className="w-[500px] max-md:w-full flex flex-col text-justify items-center">
          <h1 className="font-bold text-[64px] max-md:text-[30px] max-md:pb-8">
            Vision
          </h1>
          <p className="max-md:text-[18px]  max-md:px-8">
            At DrinkDrive, we envision a future where the roads are safe,
            vibrant, and free from the tragic consequences of drunk driving. Our
            vision is to pioneer a transformative shift in transportation,
            leading to a significant reduction in alcohol-related accidents. We
            aspire to create a world where individuals can enjoy their social
            gatherings responsibly, knowing they have a reliable and safe
            alternative for transportation, thereby making our communities safer
            for everyone.
          </p>
        </div>
        <div className="w-[500px] max-md:w-full flex flex-col text-justify items-center">
          <h1 className="font-bold text-[64px] max-md:text-[30px] max-md:pb-8">
            Mission
          </h1>
          <p className="max-md:text-[18px]  max-md:px-8">
            Our mission at DrinkDrive is to combat the scourge of drunk driving
            by providing a reliable, accessible, and responsible on-demand
            chauffeur service. We are dedicated to offering a compelling
            solution that enables individuals to make responsible choices after
            consuming alcohol, reducing the risks of accidents and ensuring
            their safe arrival at their destinations.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
