import React, { useState } from "react";
import logo from "../../assets/logo.png";
import { Link } from "react-scroll";
import { HiBars3 } from "react-icons/hi2";
import { TiTimes } from "react-icons/ti";
import { FaPhoneAlt } from "react-icons/fa";
const Header = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(true);

  const handleTabClick = () => {
    // Toggle the state to close the navbar
    setIsNavbarOpen(!isNavbarOpen);
  };

  return (
    <header className="bg-secondary fixed max-md:fixed max-md:w-screen top-0 left-0 w-full z-[1000]">
      <nav className="flex justify-between items-center p-4 max-md:px-6">
        <img src={logo} alt="drinkdrive" width={200} />
        <div className="flex gap-8 list-none max-md:hidden">
          <Link
            activeClass="active"
            to="home"
            spy={true}
            smooth={true}
            offset={-32}
            duration={500}
          >
            <p className="text-white cursor-pointer font-bold hover:text-main delay-100">
              Home
            </p>
          </Link>
          <Link
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            offset={-32}
            duration={500}
          >
            <p className="text-white cursor-pointer font-bold hover:text-main delay-100">
              About Us
            </p>
          </Link>
          <Link
            activeClass="active"
            to="service"
            spy={true}
            smooth={true}
            offset={-32}
            duration={500}
          >
            <p className="text-white cursor-pointer font-bold hover:text-main delay-100">
              Services
            </p>
          </Link>
          <Link
            activeClass="active"
            to="price"
            spy={true}
            smooth={true}
            offset={-32}
            duration={500}
          >
            <p className="text-white cursor-pointer font-bold hover:text-main delay-100">
              Pricing
            </p>
          </Link>
        </div>
        <div className="text-white flex items-center gap-2 font-bold max-md:hidden hover:text-main delay-100 cursor-pointer">
          <FaPhoneAlt />
          <a href="tel:+94114555040">+94 11 4 555 040</a>
        </div>
        <div className="max-md:hidden">
          <Link
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            offset={-32}
            duration={500}
          >
            <button className="bg-main text-white p-3 hover:opacity-70 rounded-full font-bold active:opacity-60">
              Book your chauffeur
            </button>
          </Link>
        </div>
        <div
          className={`navbar ${isNavbarOpen ? "open" : ""} md:hidden`}
          onClick={handleTabClick}
        >
          {!isNavbarOpen ? (
            <TiTimes size={45} className="text-white " />
          ) : (
            <HiBars3 size={45} className="text-white" />
          )}
          <div
            className={
              !isNavbarOpen
                ? "bg-black fixed z-[16] h-full left-0 top-0 w-[67%] ease-in duration-500"
                : "fixed left-[-100%]  "
            }
          >
            <div className="flex flex-col py-10 items-center h-auto justify-center md:hidden ">
              <Link
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-32}
                duration={500}
              >
                <p
                  className="text-white cursor-pointer py-10 hover:text-main delay-100"
                  onClick={handleTabClick}
                >
                  Home
                </p>
              </Link>
              <Link
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={-32}
                duration={500}
              >
                <p
                  className="text-white cursor-pointer py-10 hover:text-main delay-100"
                  onClick={handleTabClick}
                >
                  About Us
                </p>
              </Link>
              <Link
                activeClass="active"
                to="service"
                spy={true}
                smooth={true}
                offset={-32}
                duration={500}
              >
                <p
                  className="text-white cursor-pointer py-10 hover:text-main delay-100"
                  onClick={handleTabClick}
                >
                  Services
                </p>
              </Link>
              <Link
                activeClass="active"
                to="price"
                spy={true}
                smooth={true}
                offset={-32}
                duration={500}
              >
                <p
                  className="text-white cursor-pointer py-10 hover:text-main delay-100"
                  onClick={handleTabClick}
                >
                  Pricing
                </p>
              </Link>
            </div>
            <div className="flex items-center justify-center">
              <Link
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={-32}
                duration={500}
              >
                <button
                  className="bg-main p-3 rounded-full font-bold active:opacity-60"
                  onClick={handleTabClick}
                >
                  Book your chauffeur
                </button>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
